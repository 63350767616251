import { useEffect, useState } from 'react';

import { useInView } from 'react-intersection-observer';

import { cn } from '@/utils/helper';

const loansCard = [
  {
    title: 'Desired Terms',
    btn: 'Lend',
    btnClass: '!bg-primary',
    content: [
      {
        key: 'Principal',
        value: '2 ETH',
      },
      {
        key: 'Repayment',
        value: '2.033 ETH',
      },
      {
        key: 'APR',
        value: '20%',
      },
      {
        key: 'Duration',
        value: '30 Days',
      },
    ],
  },
  {
    title: 'Make an Offer',
    btn: 'Counter',
    btnClass: '!bg-forest-green',
    content: [
      {
        key: 'Principal',
        value: '2 ETH',
      },
      {
        key: 'APR',
        value: '25%',
      },
      {
        key: 'Repayment',
        value: '2.041 ETH',
      },
      {
        key: 'Duration',
        value: '30 Days',
      },
    ],
  },
];
const NFTLoans = () => {
  const [ref, inView] = useInView();
  const [view, setView] = useState(false);
  useEffect(() => {
    if (inView && !view) {
      setView(true);
    }
  }, [inView]);
  return (
    <div className='relative !bg-midnight-blue lg:!bg-jet-black pt-20 lg:pt-0' id='loans'>
      <img src='/images/loans-graph-left.svg' alt='loans' className='absolute left-0' />
      <div className='absolute right-0 bottom-[50px] lg:bottom-auto lg:top-0'>
        <div className='relative !bg-midnight-blue lg:!bg-jet-black'>
          <img src='/images/loans-mobile.svg' alt='loans' className='block lg:hidden' />
          <img src='/images/loans-laptop.svg' alt='loans' className='hidden lg:block' />
          <img src='/images/graph-one.svg' alt='loans' className='absolute right-0 top-[260px] z-[9] hidden lg:block' />
        </div>
      </div>
      <div className='max-w-[1366px] w-full m-auto flex flex-col items-center pb-[95px] pt-10 !bg-midnight-blue lg:!bg-jet-black'>
        <h2 className='font-bold text-[32px]/[25px] lg:text-[54px]/[45px] text-white text-center mb-[14px] relative'>
          NFT <span className='text-primary font-bold text-[32px]/[25px] lg:text-[54px]/[45px]'>Loans</span>
        </h2>
        <p className='font-normal !text-white text-[17px]/[27px] px-5 lg:px-0 lg:text-2xl/[34px] relative max-w-[698px] w-full text-center mb-[34px] lg:mb-[90px]'>Borrow and lend against all your assets, including NFTs and Real-World Assets (RWAs) like PFPs, watches, sneakers, art, and gaming collectibles.</p>
        <div ref={ref} className={cn('gap-[19px] lg:gap-[9px] !bg-transparent relative grid-cols-1 grid lg:grid-cols-3', view ? 'animate_fade animate__animated' : '')}>
          <div className='!bg-light-blue-grey rounded-lg pt-[13px] h-[375px]'>
            <img src='/images/loans-azuki.png' alt='azuki loan' className='px-3' />
            <span className='font-semibold text-xl !text-dark-blue pt-[17px] block text-center pb-[23px]'>Azuki #221</span>
            <div className='divide-x !divide-white flex [&_span]:text-sm [&_span]:font-semibold [&_span]:!text-dark-blue [&_div]:!bg-transparent !bg-transparent w-full border-t !border-t-white'>
              <div className='flex items-center flex-col w-1/2 py-[25px]'>
                <span>Loan</span>
                <span>2 ETH</span>
              </div>
              <div className='flex items-center flex-col w-1/2 py-[25px]'>
                <span>APR</span>
                <span>20%</span>
              </div>
            </div>
          </div>
          {loansCard.map(({ title, btn, content, btnClass }, idx) => {
            return (
              <div key={idx} className='!bg-light-blue-grey rounded-lg py-6 px-[11px] h-[375px]'>
                <span className='text-[17px] font-semibold !text-dark-blue block pb-6 text-center'>{title}</span>
                <div className='!bg-transparent flex flex-col gap-2'>
                  {content.map(({ key, value }, id) => {
                    return (
                      <div key={key} className='!bg-white rounded-[4px] px-[15.5px] flex justify-between'>
                        <span className='!text-dark-blue text-sm font-medium border-r border-r-light-blue-grey w-[84px] py-[14px]'>{key}</span>
                        {id === 0 && idx === 1 && (
                          <div className='flex items-center !bg-transparent -ml-8'>
                            <img src='/images/currency.png' alt='currency' className='h-max' />
                          </div>
                        )}
                        <span className='!text-dark-blue text-sm font-normal flex items-center'>{value}</span>
                      </div>
                    );
                  })}
                </div>
                <button className={cn('m-auto !mt-[19px] rounded-[4px] text-base font-bold !text-white w-[150px] h-[50px] flex justify-center items-center', btnClass)}>{btn}</button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NFTLoans;
